<template>
  <a-modal
    class="notice-mail"
    :width="700"
    :visible="show"
    :title="$t('newOrder.copy_mail')"
    @ok="handleOk"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <a-form-model ref="form" :model="form" v-if="show2">
      <!-- 下单时需通知 -->
      <a-row>
        <a-col :span="7" class="input-label">{{ $t('newOrder.order_need_notice') }}：</a-col>
        <a-col :span="17" flex>
          <template v-for="(item, index) of form.orderMails">
            <div :key="index" class="_rel _top-5" :class="{ '_mt-15': index !== 0 }">
              <div class="lang_pair">
                <a-form-model-item
                  :prop="`orderMails[${index}].email`"
                  :rules="[validate.email]"
                  style="width: 100%; margin-bottom: 0px"
                >
                  <a-input v-model="form.orderMails[index].email" placeholder="请输入需要通知的邮箱" />
                </a-form-model-item>

                <!-- 第一行固定显示 -->
                <div v-if="index === 0" class="icon-div">
                  <!-- 添加 -->
                  <img class="icon _point" @click="addOrderMail" src="@/assets/image/plus-icon.png" alt="" />
                </div>

                <!-- 删除 -->
                <div v-if="index > 0 && index < form.orderMails.length" class="icon-div">
                  <img class="icon _point" @click="delOrderMail(item.id)" src="@/assets/image/cut-icon.png" alt="" />
                </div>
              </div>
            </div>
          </template>
        </a-col>
      </a-row>

      <!-- 供应商上传终稿时需通知 -->
      <a-row class="_mt-25">
        <a-col :span="7" class="input-label">{{ $t('newOrder.order_need_notice2') }}：</a-col>
        <a-col :span="17" flex>
          <template v-for="(item, index) of form.finalDocMails">
            <div :key="index" class="_rel _top-5" :class="{ '_mt-15': index !== 0 }">
              <div class="lang_pair">
                <a-form-model-item
                  :prop="`finalDocMails[${index}].email`"
                  :rules="[validate.email]"
                  style="width: 100%; margin-bottom: 0px"
                >
                  <a-input v-model="form.finalDocMails[index].email" placeholder="请输入需要通知的邮箱" />
                </a-form-model-item>
                <!-- 第一行固定显示 -->
                <div v-if="index === 0" class="icon-div">
                  <!-- 添加 -->
                  <img class="icon _point" @click="addFinalDocMail" src="@/assets/image/plus-icon.png" alt="" />
                </div>

                <!-- 删除 -->
                <div v-if="index > 0 && index < form.finalDocMails.length" class="icon-div">
                  <img class="icon _point" @click="delFinalDocMail(item.id)" src="@/assets/image/cut-icon.png" alt="" />
                </div>
              </div>
            </div>
          </template>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import debounce from 'lodash/debounce'
const { validate } = window.$g

export default {
  name: 'NoticeMail',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      show2: true,
      form: {
        orderMails: [
          {
            id: 1,
            email: '',
            noticeType: 1,
          },
        ],
        finalDocMails: [
          {
            id: 1,
            email: '',
            noticeType: 2,
          },
        ],
      },
      submitForm: [],
      orderId: '',
      validate,
      orderMails0: [
        {
          id: 1,
          email: '',
          noticeType: 1,
        },
      ],
      finalDocMails0: [
        {
          id: 1,
          email: '',
          noticeType: 2,
        },
      ],
      orderMails1: [],
      finalDocMails1: [],
      orderMails2: [],
      finalDocMails2: [],
    }
  },
  watch: {
    id(id) {
      console.error('id :>> ', id)
      if (id) {
        // 调用回显接口
        this.getNoticeStone()
      }
    },
    async show(show) {
      if (show) {
        if (this.id) {
          await this.getNoticeStone()
          this.getData()
        } else {
          this.getData()
        }
      }
    },
  },
  computed: {
    isInit() {
      const item1 = this.form.orderMails.length === 1 && this.form.orderMails[0].email === ''
      const item2 = this.form.finalDocMails.length === 1 && this.form.finalDocMails[0].email === ''
      return item1 && item2
    },
  },
  methods: {
    async getData() {
      console.log('this.submitForm :>> ', this.submitForm)
      // 只有前端没值，才会以后端为主；
      // 如果前后端同时有值以前端为主
      const getFromSubmitForm = () => {
        console.log('888', this.submitForm)
        let id1 = 0
        let id2 = 0
        let orderMails1 = []
        let finalDocMails1 = []
        this.submitForm.forEach((_) => {
          if (_.noticeType === 1) {
            id1++
            orderMails1.push({
              id: id1,
              email: _.email,
              noticeType: 1,
            })
          }
          if (_.noticeType === 2) {
            id2++
            finalDocMails1.push({
              id: id2,
              email: _.email,
              noticeType: 2,
            })
          }
        })

        return { orderMails1, finalDocMails1 }
      }
      const orderMails0 = this.$g.deepClone(this.orderMails0)
      const finalDocMails0 = this.$g.deepClone(this.finalDocMails0)
      const { orderMails1, finalDocMails1 } = getFromSubmitForm()
      const orderMails2 = this.orderMails2
      const finalDocMails2 = this.finalDocMails2

      // 前端全有值
      if (orderMails1.length && finalDocMails1.length) {
        this.form.orderMails = orderMails1
        this.form.finalDocMails = finalDocMails1
        console.log('前端全有值')
      }

      // 前端部分有值
      else if (orderMails1.length || finalDocMails1.length) {
        if (orderMails1.length) {
          this.form.orderMails = orderMails1
        } else if (orderMails2.length) {
          this.form.orderMails = orderMails2
        }

        if (finalDocMails1.length) {
          this.form.finalDocMails = finalDocMails1
        } else if (finalDocMails2.length) {
          this.form.finalDocMails = finalDocMails2
        }
        console.log('前端部分有值')
      }

      // 前端全没值
      else if (!orderMails1.length && !finalDocMails1.length) {
        console.log('finalDocMails2.length :>> ', finalDocMails2.length)
        console.log('finalDocMails2 :>> ', finalDocMails2)
        if (orderMails2.length) {
          this.form.orderMails = orderMails2
        } else {
          this.form.orderMails = orderMails0
        }
        if (finalDocMails2.length) {
          this.form.finalDocMails = finalDocMails2
        } else {
          this.form.finalDocMails = finalDocMails0
          console.log('finalDocMails0 :>> ', finalDocMails0)
          console.log('this.form.finalDocMails :>> ', this.form.finalDocMails)
        }

        console.log('前端全没值')
      }
    },
    async getNoticeStone() {
      try {
        const params = { orderId: this.id }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('getNoticeStone', params)
        console.log(`data`, data)
        let id1 = 0
        let id2 = 0
        const orderMails = data
          .map((_) => {
            const { email, orderId, noticeType } = _
            if (noticeType === 1) {
              id1++
              return {
                id: id1,
                email,
                orderId,
                noticeType,
              }
            }
          })
          .filter(Boolean)
        const finalDocMails = data
          .map((_) => {
            const { email, orderId, noticeType } = _
            if (noticeType === 2) {
              id2++
              return {
                id: id2,
                email,
                orderId,
                noticeType,
              }
            }
          })
          .filter(Boolean)

        console.log({ orderMails, finalDocMails })
        this.orderMails2 = orderMails
        this.finalDocMails2 = finalDocMails
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async saveNoticeStone(orderId) {
      try {
        console.error('saveNoticeStone---')
        let arr = []
        if (this.isInit) {
          console.log('没填邮箱，不要请求')
        } else {
          this.submitForm.forEach((_) => {
            _.orderId = orderId
          })
          arr = this.$g.delNullAttrObj(this.$g.deepClone(this.submitForm))
        }

        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('saveNoticeStone', { stoneOrderNoticeRequestList: arr, orderId })
        console.warn(`saveNoticeStone-data`, data)
        this.$g.resetData(this)
        return 1
      } catch (err) {
        this.$g.resetData(this)
        this.$httpNotify(err)
        return 0
      }
    },
    addOrderMail: debounce(function () {
      this.form.orderMails.push({
        id: this.form.orderMails[this.form.orderMails.length - 1].id + 1,
        email: undefined,
        noticeType: 1,
      })
    }, 100),
    delOrderMail: debounce(function (id) {
      this.$g.del(this.form.orderMails, id)
    }, 100),
    addFinalDocMail: debounce(function () {
      this.form.finalDocMails.push({
        id: this.form.finalDocMails[this.form.finalDocMails.length - 1].id + 1,
        email: undefined,
        noticeType: 2,
      })
    }, 100),
    delFinalDocMail: debounce(function (id) {
      this.$g.del(this.form.finalDocMails, id)
    }, 100),
    handleOk() {
      console.log('333', this.submitForm)
      this.$refs.form.validate(async (valid) => {
        console.log('valid', valid)
        if (valid) {
          console.log('isInit', this.isInit)
          if (!this.isInit) {
            let arr = [...this.$g.deepClone(this.form.orderMails), ...this.$g.deepClone(this.form.finalDocMails)]
            arr.forEach((_) => {
              delete _.id
              _['orderId'] = this.orderId
            })
            // console.log('通知邮箱确定 arr :>> ', arr)
            console.log('邮箱确定', arr[0].email)
            this.submitForm = arr
          } else {
            this.submitForm = ''
          }
          this.$emit('close')
        } else {
          console.error('邮箱格式错误')
        }
      })
    },
    handleCancel() {
      console.log('handleCancel')
      this.$emit('close')
      setTimeout(() => {
        this.show2 = false
      }, 200)
      setTimeout(() => {
        this.show2 = true
      }, 400)
    },
  },
}
</script>

<style lang="less" scoped>
.lang_pair {
  display: flex;
  line-height: 32px;
}
.input-label {
  height: 32px;
  line-height: 32px;
  text-align: right;
  padding-top: 8px;
  padding-right: 5px;
}
.icon-div {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.icon {
  width: 20px;
  height: 20px;
}
</style>
